import { Collection, DynamicTokens } from '@reservoir0x/reservoir-kit-ui';
import { useState, useEffect, useMemo } from 'react'
import { contractAddresses } from 'utils/addresses'

const API_KEY = process.env.RESERVOIR_API_KEY

export const useCustomCollections = ({
  collectionAddress,
}: {
  collectionAddress: string | null
}) => {
  const [collections, setCollections] = useState<Collection[] | []>([])
  const [collection, setCollection] = useState<Collection | null>(null)
  const [tokens, setTokens] = useState<DynamicTokens | []>([])
  const [isValidating, setIsValidating] = useState<boolean>(false)

  useEffect(() => {
    const fetchCollectionTokens = async () => {
      setIsValidating(true)
      try {
        // Fetch tokens data
        const API_URL = `https://api-base.reservoir.tools/tokens/v6?collection=${collectionAddress}`
        const response = await fetch(API_URL, {
          method: 'GET',
          headers: {
            accept: '*/*',
            'x-api-key': API_KEY!,
          },
        })

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        setTokens(data.tokens)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsValidating(false)
      }
    }

    const fetchCollections = async () => {
      setIsValidating(true)
      try {
        const contractCollections = Object.values(contractAddresses)

        const collectionsData = await Promise.all(
          contractCollections.map(async (address) => {
            const API_URL = `https://api-base.reservoir.tools/collections/v7?id=${address}&includeSalesCount=true&includeMintStages=true&includeSecurityConfigs=true&normalizeRoyalties=true`
            const response = await fetch(API_URL, {
              method: 'GET',
              headers: {
                accept: '*/*',
                'x-api-key': API_KEY!,
              },
            })

            if (!response.ok) {
              throw new Error('Network response was not ok')
            }

            const data = await response.json()
            return data.collections[0]
          })
        )
        setCollections(collectionsData)
      } catch (error) {
        console.error('Error fetching collections:', error)
        throw error
      } finally {
        setIsValidating(false)
      }
    }

    const fetchCollection = async () => {
      setIsValidating(true)
      try {
        const API_URL = `https://api-base.reservoir.tools/collections/v7?id=${collectionAddress}&includeSalesCount=true&includeMintStages=true&includeSecurityConfigs=true&normalizeRoyalties=true`
        const response = await fetch(API_URL, {
          method: 'GET',
          headers: {
            accept: '*/*',
            'x-api-key': API_KEY!,
          },
        })

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const data = await response.json()
        setCollection(data.collections[0])
      } catch (error) {
        console.error('Error fetching collections:', error)
        throw error
      } finally {
        setIsValidating(false)
      }
    }

    if (collectionAddress) {
      fetchCollectionTokens()
      fetchCollection()
    }
    fetchCollections()
  }, [])

  const memoizedCollections = useMemo(() => collections, [collections])
  const memoizedCollection = useMemo(() => collection, [collection])
  const memoizedTokens = useMemo(() => tokens, [tokens])

  return {
    collections: memoizedCollections,
    collection: memoizedCollection,
    tokens: memoizedTokens,
    isValidating,
  }
}
