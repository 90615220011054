export const contractAddresses = {
  pop: '0xEDd442DC2090d71b80451450c2d1bd0e563072d5',
  trap: '0x5f3bb0B229954DD5EC1BA05dbD1C1AD27fb35e7C',
  techno: '0xe4D8a3552a2A7c836d8bC1BfDA32D52DbA790B8B',
  latinHouse: '0xEba828D51928720E0aAda525B0418Ae8662F1F5c',
  hipHop: '0x9E660C7E14387b1F12deDd3FC4404E0b442BE4df',
  edm: '0x026d9493C44a8e1B7536596505Fd6bc35196dDD4'

}

export const channelAddresses = {
  pop: 'https://livepeercdn.studio/hls/8a77ek3bkrqilw20/index.m3u8',
  trap: 'https://livepeercdn.studio/hls/974epcqbu8jzxds1/index.m3u8',
  techno: 'https://livepeercdn.studio/hls/e8f0iby7eor15ilu/index.m3u8',
  latinHouse: 'https://livepeercdn.studio/hls/475f2bohxgg3igpv/index.m3u8',
  hipHop: 'https://livepeercdn.studio/hls/6f70dqp8bezkt9yo/index.m3u8',
  edm: 'https://livepeercdn.studio/hls/f622kw8ovw3yrlv6/index.m3u8'
}