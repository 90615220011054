import React from 'react'
import Image from 'next/image'
import { createStitches } from '@stitches/react'

const { styled } = createStitches({
  theme: {
    colors: {
      primary: '#5B5BD6',
      white: '#FFFFFF',
      black: '#000000',
    },
    fontSizes: {
      large: '2rem',
      medium: '1rem',
    },
  },
})

const BannerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2rem',
  position: 'relative',
  height: '60vh',
  width: '100%',
  borderRadius: 12,
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const TextContainer = styled('div', {
  maxWidth: '35%',
  zIndex: 1,
  '@media (max-width: 768px)': {
    maxWidth: '80%',
    textAlign: 'center',
  },
  color: '$white',
})

const Heading = styled('h1', {
  fontSize: '$large',
  fontWeight: 'bold',
  marginBottom: '1rem',
})

const Description = styled('p', {
  fontSize: '$medium',
  marginBottom: '1rem',
})

const Button = styled('button', {
  padding: '0.5rem 1rem',
  backgroundColor: '$primary',
  color: '$white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$black',
  },
})

const ImageOverlay = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  zIndex: 0,
  borderRadius: 12,
})

const HeroBanner = () => {
  const scrollToSection = () => {
    const section = document.querySelector('#featured')
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <BannerContainer>
      <ImageOverlay />
      <Image
        src="/banner.png"
        alt="Hero Banner"
        layout="fill"
        objectFit="cover"
        quality={100}
        style={{ borderRadius: 12, opacity: 0.7 }}
      />
      <TextContainer>
        <Heading>Explore the World of NFT Music</Heading>
        <Description>
          Discover and purchase exclusive music pieces on our NFT marketplace.
          Join the future of music ownership today!
        </Description>
        <Button onClick={scrollToSection}>Explore</Button>
      </TextContainer>
    </BannerContainer>
  )
}

export default HeroBanner
